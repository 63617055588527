import React from "react"
import Layout from "../layout/dark-default"
import SectionMastHead from "../components/organism/product/section-masthead"
import SectionSlider from "../components/organism/product/section-slider"
import SectionProduct from "../components/organism/product/section-product"
import SectionProducts from "../components/organism/product/section-products2"
import db from "../firebase"
import SEO from "../layout/SEO"
import { useState } from "react"
import { useEffect } from "react"
import { getDownloadURL, getStorage, ref } from "firebase/storage"

const Product = () => {
  // const { project } = pageContext
  // const ProjectArr = project.projectList.map(x => x)
  // console.log("projectP", project, ProjectArr)
  const [projectData, setProjectData] = useState([]);
  const storage = getStorage();
  const [projectImage, setProjectImage] = useState([]);
  const [projectImage2, setProjectImage2] = useState([]);

  useEffect(() => {
      db.collection('Project').onSnapshot(snapshot => {
        setProjectData(snapshot.docs.map(doc => ({
          id: doc.id,
          canonical_url: doc.data().canonical_url,
          meta_desc: doc.data().meta_desc,
          meta_title: doc.data().meta_title,
          project_desc: doc.data().project_desc,
          og_image: doc.data().og_image,
          project_image: doc.data().project_image,
          project_title: doc.data().project_title
        })))
      })
  }, [])

  useEffect(() => {
    projectData[1] && projectData[1].project_image.map(image => {
      const imagaRef = ref(storage, image);
      setTimeout(() => {
        getDownloadURL(imagaRef).then(url => {
          setProjectImage((prev) => [...prev, url]);
        })
      }, 10);
    })
  }, [projectData])

  useEffect(() => {
    projectData[0] && projectData[0].project_image.map(image => {
      const imagaRef = ref(storage, image);
      setTimeout(() => {
        getDownloadURL(imagaRef).then(url => {
          setProjectImage2((prev) => [...prev, url]);
        })
      }, 10);
    })
  }, [projectData])

  console.log(projectData, "projectData");
  // console.log(projectImage, "projectImage");







  return (
    <>
    {projectData[1]?<SEO canonicalUrl={projectData[1].canonical_url} title={projectData[1].meta_title} meta_desc={projectData[1].meta_desc} />:null}
      <Layout>
      {/* {projectData[1] && <SectionMastHead title={"Projects"} subprod={"section_sub_pb"} />} */}
        {projectData.map((x, i) => {
          return (
            <>
            {<SectionMastHead applyStyle topPadding={"Infinity Swimming Pool at a Farm House in Delhi" == x.project_title ? 'addTopPadding' : null} projects title={x.project_title} desc={x.project_desc} subprod={"section_sub_pb"} />}
           { x.project_title === "Infinity Swimming Pool at a Farm House in Delhi" ? <SectionProducts technical={false}  products={projectImage2} /> : <SectionProducts technical={false}  products={projectImage} />}
            </>
          )
        })}
        {/* {product.products ? <SectionProducts technical={false}  products={product.products} />:null}
       {product.technical ? <SectionProducts technical products={product.technical} />:null}  */}
      </Layout>
    </>
  )
}

export default Product
