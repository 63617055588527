import React, { useEffect } from "react"
import SectionBanner from "../../molecule/product/section-banner-img"
import SectionProductCards from "../../molecule/product/section-product-cards"
import { getDownloadURL, getStorage, get, ref } from "firebase/storage"
import { useState } from "react"


const SectionProduct = ({ data }) => {
  const storage = getStorage();
  const [productbanner, setProductBanner] = useState('');
  useEffect(() => {
      const imagaRef = ref(storage, data.subcategory_product_banner);
      getDownloadURL(imagaRef).then(url => {
        setProductBanner( url);
      })
}, [data]);

  return (
    <div className="product">
      <SectionBanner title={data.title} desc={data.Description} img={productbanner} link={"/product/"+ data.parent_productslug +"/"+ data.slug}/>
      <SectionProductCards title={data.subcategory_product_card_title} cards={data.subcategory_product_card_img} />
    </div>
  )
}
export default SectionProduct
