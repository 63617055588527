import React, { useEffect } from "react"
import { getDownloadURL, getStorage, get, ref } from "firebase/storage"
import DivProductCard from "./div-product-card"
import { useState } from "react";

const SectionProductCards = ({ cards, title }) => {
  const storage = getStorage();
  const [URL, setUrl] = useState([]);

  useEffect(() => {    
    setUrl([]);
    const getImgUrl = async () => {
      cards.map(e => {
        const imagaRef = ref(storage, e);
        getDownloadURL(imagaRef).then(url => {
          setUrl((prev) => [...prev, url]);
        })
      })
    }
    getImgUrl(cards);
  }, [cards])

  return (
    <section class="section section-grid section_pt section_pb bg-dark color-white">
      <div class="container">
        <div
          class="grid grid_fluid-6 js-grid"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          {URL && URL.map((card, index) => (
            <DivProductCard
              title={title}
              imgUrl={card}
              key={index}
            />
          ))}
        </div>
      </div>
    </section>
  )
}
export default SectionProductCards
