import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = ({ title, meta_desc, canonicalUrl }) => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <title>{title}</title>
        <meta name="description" content={meta_desc} />
      </Helmet>
    </>
  )
}

export default SEO